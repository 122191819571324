<template>
  <div class="account">
    <van-swipe :autoplay="3000 * 60" class="my-swipe">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img v-lazy="image.img" />
        <!-- <div class="my-swipe-text">
          <h3 class="my-swipe-text-title">{{ image.text }}</h3>
        </div> -->
      </van-swipe-item>
    </van-swipe>
    <van-grid :column-num="4" :border="false" class="account-grid">
      <van-grid-item
        :icon="newMenu.main_nav.new_account"
        :text="$t('Menu.openAccount')"
        to="/home_account/open"
      />
      <van-grid-item
        :icon="newMenu.main_nav.withdrawal"
        :text="$t('Menu.deposit')"
        @click="fundsOnSelect"
      />
      <van-grid-item
        :icon="newMenu.main_nav.deposit"
        :text="$t('Menu.totalWithdrawal')"
        @click="enter"
      />
      <van-grid-item
        :icon="newMenu.main_nav.transfer"
        :text="$t('Menu.transfer')"
        @click="transfer"
      />
      <van-grid-item
        :icon="newMenu.main_nav.copy_trader"
        :text="$t('Menu.copyTrade')"
        @click="documentary"
        v-show="showSingal"
      />
      <van-grid-item
        :icon="newMenu.main_nav.pc"
        :text="$t('Menu.closeReport')"
        @click="documentary"
        v-show="!showSingal"
      />
      <van-grid-item
        :icon="newMenu.main_nav.account_ls"
        :text="$t('Menu.fundsHistory')"
        @click="documentary"
        v-show="!showSingal"
      />
      <van-grid-item
        :icon="newMenu.main_nav.singal"
        :text="$t('Menu.signal')"
        @click="singal"
        v-show="showSingal"
      />
      <van-grid-item
        :icon="newMenu.main_nav.cc"
        :text="$t('Menu.positionReport')"
        @click="position"
      />
      <van-grid-item :icon="svg.more" :text="$t('app.more')" @click="more" />
    </van-grid>
    <!-- <van-grid :column-num="4" :border="false" class="account-grid">
      <van-grid-item
        icon="add-o"
        :text="$t('Menu.openAccount')"
        to="/home_account/open"
      />
      <van-grid-item
        icon="balance-pay"
        :text="$t('Menu.deposit')"
        @click="fundsOnSelect"
      />
      <van-grid-item
        icon="cash-back-record"
        :text="$t('Menu.totalWithdrawal')"
        @click="enter"
      />
      <van-grid-item
        icon="refund-o"
        :text="$t('Menu.transfer')"
        @click="transfer"
      />
      <van-grid-item
        icon="balance-pay"
        :text="$t('Menu.copyTrade')"
        @click="documentary"
      />
      <van-grid-item icon="aim" :text="$t('Menu.signal')" @click="singal" />
      <van-grid-item
        icon="records"
        :text="$t('Menu.positionReport')"
        @click="position"
      />
      <van-grid-item :icon="svg.more" :text="$t('app.more')" @click="more" />
    </van-grid> -->

    <van-cell-group :border="false" class="account-title account-trade">
      <van-cell
        :icon="newMenu.main_nav.ye"
        :title="$t('AccountInfo.balance')"
        :value="allbanlance + '$'"
        is-link
        :border="false"
        to="/home_account/balance"
        center
      />
      <van-cell
        :icon="newMenu.main_nav.deposit"
        :title="$t('Funds.withdrawal')"
        :value="allwithdrawl + '$'"
        is-link
        :border="false"
        to="/funds/withdrawals"
        center
      />
      <van-cell
        :icon="newMenu.main_nav.withdrawal"
        :title="$t('Funds.deposit')"
        :value="alldeposit + '$'"
        is-link
        :border="false"
        to="/funds/deposit"
        center
      />
      <!-- <van-cell
        :title="allwithdrawl"
        :label="$t('Withdrawal')"
        class="account-trade-num"
      >
        <template #default>
          <div class="account-trade-value">
            {{ alldeposit }}
            <span class="account-trade-value-company">$</span>
          </div>
          <div class="account-trade-profit">
            {{ $t("Deposit") }}
          </div>
        </template>
      </van-cell> -->
      <!-- <van-cell
        class="account-trade-num"
      >
        <template #default>
          <div class="account-trade-value">
            {{ alldeposit }}
            <span class="account-trade-value-company">$</span>
          </div>
          <div class="account-trade-profit">
            {{ $t("Deposit") }}
          </div>
        </template>
      </van-cell> -->
    </van-cell-group>
    <van-cell-group :border="false" class="account-title account-tool">
      <van-cell
        :title="$t('commonTools')"
        :value="$t('app.more')"
        is-link
        :border="false"
        to="/home_account/more"
      />
      <van-grid :border="false" :column-num="4" class="account-grid2">
        <van-grid-item
          :icon="svg.change_pwd"
          :text="$t('Register.userPassword')"
          to="/home_account/change_pwd"
        />
        <van-grid-item
          :icon="svg.change_gg"
          :text="$t('NewAccount.leverage')"
          to="/member/leverage"
        />
        <!-- <van-grid-item
          :icon="svg.change_bg"
          :text="$t('Menu.positionReport')"
          to="/report/position"
        /> -->
        <van-grid-item
          :icon="svg.change_gl"
          :text="$t('customerManagement')"
          to="/funds/Underling"
        />
        <van-grid-item :icon="svg.change_tg" :text="$t('app.promote')" to="share" />
      </van-grid>
    </van-cell-group>
    <div class="van-safe-area-bottom"></div>
  </div>
</template>

<script>
import { GetVWSignalPages, getmemberdata } from "@/api";
import baseConfig from '@/utils/baseConfig';
export default {
  data() {
    return {
      images: [
        { img: require("@/assets/image/crm/swiper_1.jpg"), text: "省心省时间" },
      ],
      svg: {
        change_pwd: require("@/assets/image/new_crm/transaction_password.png"),
        change_gg: require("@/assets/image/new_crm/change_lervr.png"),
        change_bg: require("@/assets/image/crm/cc.svg"),
        change_tg: require("@/assets/image/new_crm/extension.png"),
        change_gl: require("@/assets/image/new_crm/account_customer.png"),
        more: require("@/assets/image/crm/more.png"),
      },
      newMenu: {
        main_nav: {
          new_account: require("@/assets/image/crm/new_account.png"),
          withdrawal: require("@/assets/image/crm/deposit.png"),
          deposit: require("@/assets/image/crm/withdrawal.png"),
          transfer: require("@/assets/image/crm/transfer.png"),
          copy_trader: require("@/assets/image/crm/copytrade.png"),
          singal: require("@/assets/image/crm/signal.png"),
          cc: require("@/assets/image/crm/positions.png"),
          ye: require("@/assets/image/new_crm/balance.png"),
          pc: require("@/assets/svg/pc.svg"),
          account_ls: require("@/assets/image/crm/statement.png"),
        },
      },
      showSingal: baseConfig.showSingal,
      name: "",
      traderNum: "",
      profit: "",
      allbanlance: 0.0,
      alldeposit: 0.0,
      allwithdrawl: 0.0,
      eyesShow: true,
    };
  },
  created() {
    this.getMoney();
  },
  methods: {
    fundsOnSelect() {
      let url = "/funds/deposit";
      this.$router.push({
        path: url,
      });
    },
    enter() {
      let url = "/funds/withdrawals";
      this.$router.push({
        path: url,
      });
    },
    documentary() {
      let url = "/home_account/documentary";
      this.$router.push({
        path: url,
      });
    },
    singal() {
      let url = "/home_account/singal";
      this.$router.push({
        path: url,
      });
    },
    position() {
      let url = "/report/position";
      this.$router.push({
        path: url,
      });
    },
    transfer() {
      let url = "/funds/transfer";
      this.$router.push({
        path: url,
      });
    },
    more() {
      let url = "/home_account/more";
      this.$router.push({
        path: url,
      });
    },
   
    async getMoney() {
      let res = await getmemberdata({});
      // this.eyesShow ? this.allbanlance = res.msg.allbanlance : '****';
      // this.eyesShow ? this.alldeposit = res.msg.alldeposit : '****';
      // this.eyesShow ? this.allwithdrawl = res.msg.allwithdrawl : '**';
      this.allbanlance = res.msg.allbanlance.toFixed(2);
      this.alldeposit = res.msg.alldeposit.toFixed(2);
      this.allwithdrawl = res.msg.allwithdrawl.toFixed(2);
    },
    showEyes() {
      this.eyesShow = !this.eyesShow;
      if (this.eyesShow) {
        this.getMoney();
      } else {
        this.allbanlance = "****";
        this.alldeposit = "****";
        this.allwithdrawl = "**";
      }
    },
  },
};
</script>

<style scoped>
.account {
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
  overflow-y: auto;
}
.my-swipe {
  height: 200px;
  overflow: hidden;
}
.my-swipe .van-swipe-item img {
  display: block;
  width: 100%;
}
.my-swipe .van-swipe-item {
  position: relative;
}
.my-swipe-text {
  position: absolute;
  top: 50px;
  left: 50px;
  color: #fff;
}
.my-swipe-text-title {
  font-size: 30px;
}
.account-grid {
  padding: 20px 0;
}
.account-grid >>> .van-grid-item__content {
  padding: 5px;
}
.account-grid-open {
  padding: 20px;
  background-color: pink;
}
.account-grid >>> .van-grid-item__icon {
  font-size: 32px;
}
.account-title >>> .van-cell__title {
  font-size: 14px;
}
.account-trade-num >>> .van-cell__title {
  font-size: 30px;
}
.account-trade-num {
  padding: 20px;
}
.account-trade-num >>> .van-cell__label {
  margin-top: 10px;
}
.account-trade-value {
  font-size: 30px;
  color: #000;
  font-weight: 700;
}
.account-trade-value-company {
  font-size: 16px;
}
.account-tool {
  margin-top: 20px;
}
.account >>> .van-grid-item__text {
  white-space: nowrap;
}
.account-grid2 {
  flex-wrap: nowrap;
}
.van-grid >>> .van-grid-item {
  width: 25%;
}
img {
  vertical-align: baseline;
}
</style>